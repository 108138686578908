// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-factsheet-index-jsx": () => import("./../../../src/components/Factsheet/index.jsx" /* webpackChunkName: "component---src-components-factsheet-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/About.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-explore-jsx": () => import("./../../../src/pages/Explore.jsx" /* webpackChunkName: "component---src-pages-explore-jsx" */),
  "component---src-pages-factsheet-jsx": () => import("./../../../src/pages/Factsheet.jsx" /* webpackChunkName: "component---src-pages-factsheet-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-share-jsx": () => import("./../../../src/pages/Share.jsx" /* webpackChunkName: "component---src-pages-share-jsx" */)
}

