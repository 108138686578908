module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"./config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Texas Environmental Justice Explorer","short_name":"TXEJ Explorer","description":"A simple tool to explore the EJ hotspots in Texas.","start_url":"/?utm_source=a2hs","background_color":"#253688","theme_color":"#253688","display":"standalone","icon":"src/images/txrisinglogo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"03dcc8fb3cfc974cd8fe6b9ed99594d6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
